import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Link } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ExpandMoreOutlined } from "@mui/icons-material";
import { openInNewTab } from "../../utils/linkUtil";
import { useTitle } from "src/hooks/useTitle";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  bottomContainer: {
    paddingBottom: 20,
  },
  link: {
    textDecoration: "underline",
  },
}));

const Help: React.FC = () => {
  useTitle("Help");

  const classes = useStyles();

  const renderItem = (title: React.ReactNode, caption: React.ReactNode) => {
    return (
      <Grid item xs={12} className={classes.bottomContainer}>
        <div>
          <strong>{title}</strong>
        </div>
        <div>{caption}</div>
      </Grid>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <h2>Product App Help</h2>
      </Grid>
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreOutlined />} aria-controls="panel1a-content" id="panel1a-header">
            <div className={classes.heading}>General</div>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction="row">
              {renderItem(
                "Info",
                "Product app shows product info, inventory, pricing, promotions, purchase orders and more."
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreOutlined />} aria-controls="panel2a-content" id="panel2a-header">
            <div className={classes.heading}>iOS &gt; Safari &amp; Chrome</div>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction="row">
              {renderItem(
                "Inventory Not Sorting By Closest Location",
                "In your device's Settings > Location > set Safari to Ask. "
              )}
              {renderItem(
                "Location Services Always Prompting (iOS)",
                "To always allow location services, tap the aA in the address bar > Website Settings > Set Location to Allow instead of Ask. "
              )}
              {renderItem(
                "Barcode Scanning / Camera",
                "To enable scanning barcodes, tap the aA in the address bar > Website Settings > Set Camera to Allow.  "
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreOutlined />} aria-controls="panel3a-content" id="panel3-header">
            <div className={classes.heading}>Inventory</div>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction="row">
              <Grid item xs={12} className={classes.bottomContainer}>
                <div>
                  <strong>Inventory &amp; Available Buckets</strong>
                </div>
                <div>
                  Have a read of{" "}
                  {
                    <Link
                      className={classes.link}
                      color="primary"
                      onClick={() =>
                        openInNewTab(
                          "https://jbhifi.sharepoint.com/sites/Backstage/Supply%20Chain/PDA%20On%20Stage%20Real%20Time%20Inventory%20Guide.pdf#search=inventory%20quantities"
                        )
                      }
                    >
                      this
                    </Link>
                  }{" "}
                  Backstage article about OnStage for what the various inventory quantities mean.
                </div>
              </Grid>
              {renderItem(
                "Inventory Sorting",
                "If location services is enabled on your device for this site, then inventory options will be sorted by store closest to you."
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default Help;
