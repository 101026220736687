import React, { useContext, useEffect, useState, useCallback } from "react";
import { Grid, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import Button from "@jbhi-fi/lanyard-ui/components/Button/Button";
import TextField from "@jbhi-fi/lanyard-ui/components/TextField/TextField";
import DataGrid from "../Product/Desktop/components/DataGrid";
import { JBPricingHistoryResponse } from "src/models/product/JBPricingHistoryResponse";
import { fetchJBPricingHistoryAsync, fetchPromosAsync } from "src/infrastructure/http/appApiService";
import { useCountry } from "src/components/Context/CountryContext";
import { booleanType, currencyType, dateTimeType } from "src/utils/dataGridFormat";
import PageContext from "src/components/Context/PageContext";
import { hasPermission } from "src/infrastructure/auth/PermissionUtils";
import { UserRoles } from "src/infrastructure/auth/UserRoles";
import { useCurrentUserStore } from "src/hooks/useCurrentUserStore";
import { Promotion } from "src/models/product";
import { Moment } from "moment";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { getConsts } from "../../utils/consts";
import { useTitle } from "src/hooks/useTitle";

type RouteParams = {
  sku?: string;
};

interface PricingHistoryModel {
  timestamp: string;
  displayPriceInc: number;
  displayWasPrice: boolean;
  wasPrice: number | null;
  promosActive:string[];
  promosActiveEndDates:Moment[];
  promosApplied: string[];
  promosAppliedEndDates:Moment[];
}

const JBHistoricPricing: React.FC = () => {
  const consts = getConsts();
  const history = useHistory();
  const location = useLocation();

  const { country } = useCountry();
  const { sku } = useParams<RouteParams>();
  
  const [searching, setSearching] = useState(false);
  const [prices, setPrices] = useState<JBPricingHistoryResponse[]>([]);
  const [promotions, setPromotions] = useState<Promotion[]>([]);
  const [priceHistoryRecords, setPriceHistoryRecords] = useState<PricingHistoryModel[]>([]);

  const [inputSku, setInputSku] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [contextData] = useContext(PageContext);
  const { userProfile } = contextData;
  const { onlineLocationId } = useCurrentUserStore();

  const title = sku ? `Historic Pricing | ${sku}` : "Historic Pricing";
  useTitle(title);

  // Memorise runSearch
  const runSearch = useCallback(
    async (searchSku: string) => {
      try {
        setErrorMessage(null);
        setSearching(true);
  
        const [pricingHistoryResponse, promosResponse] = await Promise.all([
          fetchJBPricingHistoryAsync(searchSku, country),
          fetchPromosAsync(searchSku, onlineLocationId, country),
        ]);
  
        if (pricingHistoryResponse !== undefined && pricingHistoryResponse.length > 0) {
          setPrices(pricingHistoryResponse);
        } else {
          setPrices([]);
          setErrorMessage("The SKU you entered could not be found.");
        }
  
        if (promosResponse !== undefined) {
          setPromotions(promosResponse);
        } else {
          setPromotions([]);
        }
  
        setSearching(false);
      } catch (e) {
        console.error(e);
        setSearching(false);
        setPrices([]);
        setPromotions([]);
        setErrorMessage("The SKU you entered could not be found.");
      }
    },
    [country, onlineLocationId] 
  );

  // perform search on route change
  useEffect(() => {
    if(sku != null){
      setInputSku(sku);
      runSearch(sku);
    }
    else{
      setInputSku("");
      setPrices([]);
      setPromotions([]);
      setErrorMessage(null);
    }
  }, [sku, runSearch]);

  // Update PriceHistoryRecords for data grid
  useEffect(() => {
    const data = prices.map(price => {
      // sort promo applied by DateTo in descending order
      const promosAppliedSorted = price.promosApplied.map(pa => {
        return {
          promoId: pa, 
          dateTo: promotions.find(p => p.id === pa)?.dateTo ?? null
        };
      })
      .sort((a, b) => {
        if (a.dateTo === null) return 1; 
        if (b.dateTo === null) return -1;
        return b.dateTo.diff(a.dateTo);
      });

      // sort promo active by DateTo in descending order
      const promosActiveSorted = price.promosActive.map(pa => {
        return {
          promoId: pa, 
          dateTo: promotions.find(p => p.id === pa)?.dateTo ?? null
        };
      })
      .sort((a, b) => {
        if (a.dateTo === null) return 1; 
        if (b.dateTo === null) return -1;
        return b.dateTo.diff(a.dateTo);
      });

      const priceHistoryRecord : PricingHistoryModel =
      {
        timestamp: price.timestamp,
        displayPriceInc: price.displayPriceInc,
        displayWasPrice: price.displayWasPrice,
        wasPrice: price.wasPrice,
        promosApplied: promosAppliedSorted.map(o => o.promoId),
        promosAppliedEndDates: promosAppliedSorted.map(o => o.dateTo),
        promosActive: promosActiveSorted.map(o => o.promoId),
        promosActiveEndDates: promosActiveSorted.map(o => o.dateTo),
      };

      return priceHistoryRecord;
    });

    setPriceHistoryRecords(data);
  }, [prices, promotions]);

  if (!hasPermission(UserRoles.PricingHistoricPricing, userProfile.roles)) {
    window.location.href = "/";
    return null;
  }

  const columns: GridColDef[] = [
    {
      field: "timestamp",
      headerName: "Time",
      ...dateTimeType,
      flex: 1,
    },
    {
      field: "displayPriceInc",
      headerName: "Price",
      ...currencyType,
      width: 120,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "wasPrice",
      headerName: "Was Price",
      ...currencyType,
      width: 120,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "displayWasPrice",
      headerName: "Display Was Price",
      ...booleanType,
      width: 120,
    },
    {
      field: "promosActive",
      headerName: "Active Promo",
      width: 120,
      renderCell: (value) => {
        return (
          <div>
            {value.value.map((v, index) => (
              <div key={index}>{v}</div>
            ))}
          </div>
        );
      },
    },
    {
      field: "promosActiveEndDates",
      headerName: "End Date",
      width: 120,
      renderCell: (value) => {
        return (
          <div>
            {value.value.map((date, index) => (
              <div key={index}>{date === null ? "-" : date.format("DD/MM/YYYY")}</div>
            ))}
          </div>
        );
      },
    },
    {
      field: "promosApplied",
      headerName: "Applied Promo",
      width: 120,
      renderCell: (value) => {
        return (
          <div>
            {value.value.map((v, index) => (
              <div key={index}>{v}</div>
            ))}
          </div>
        );
      },
    },
    {
      field: "promosAppliedEndDates",
      headerName: "End Date",
      width: 120,
      renderCell: (value) => {
        return (
          <div>
            {value.value.map((date, index) => (
              <div key={index}>{date === null ? "-" : date.format("DD/MM/YYYY")}</div>
            ))}
          </div>
        );
      },
    },
  ];

  const handleSearch = async () => {
    const newPath = `${consts.routerPath.historicPricing}/${inputSku}`;
    
    if(newPath !== location.pathname) {
      history.push(`${consts.routerPath.historicPricing}/${inputSku}`);
    }
  }

  return (
    <>
      <Grid container paddingBottom={2}>
        <Grid item xs={12} paddingBottom={2}>
          <h2>Pricing History</h2>
        </Grid>

        <Grid item paddingRight={2} width={320}>
          <TextField
            id="pricingHistorySearch"
            name="pricingHistorySearch"
            hasError={errorMessage != null}
            errorMsg={errorMessage}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
            value={inputSku}
            placeholder="Search By SKU"
            onChange={(e) => setInputSku(e.target.value)}
          />
        </Grid>
        <Grid item>
          <Button type={"primary"} onClick={handleSearch} loading={searching} disabled={searching}>
            SEARCH
          </Button>
        </Grid>
      </Grid>

      {priceHistoryRecords.length > 0 && (
        <>
          <Typography variant="h2">
            Showing pricing history for {sku}
          </Typography>
          <Grid container marginTop={2}>
              <DataGrid
                columns={columns}
                rows={priceHistoryRecords}
                getRowId={() => crypto.randomUUID()}
                isLoading={searching}
                hideFooter
                autoHeight
                disableColumnResize
                getRowHeight={() => "auto"}
                sx={{
                  "& .MuiDataGrid-row": {
                    minHeight: "52px !important",
                  },
                }}
              ></DataGrid>
          </Grid>    
        </>
      )}
    </>
  );
};

export default JBHistoricPricing;
