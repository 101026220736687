const callbackMap: Record<string, [(data: any) => void, (error: any) => void]> = {};

class JbJsAsyncCallbackHandler implements IJbJsAsyncCallbackHandler {
  registerAsyncCallback<T>(callbackId: string, resolve: (data: T) => void, reject: (error: any) => void) {
    callbackMap[callbackId] = [resolve, reject];
  }

  handleAsyncResponse(callbackId: string, data: any) {
    const cb = callbackMap[callbackId];
    if (!cb) return;

    const resolve = cb[0];
    delete callbackMap[callbackId];

    resolve(data);
  }

  handleAsyncError(callbackId: string, error: any) {
    const cb = callbackMap[callbackId];
    if (!cb) return;

    const reject = cb[1];
    delete callbackMap[callbackId];

    reject(error);
  }
}

const jbJsAsyncCallbackHandler = (window.JbJsAsyncCallbackHandler = new JbJsAsyncCallbackHandler());

export default jbJsAsyncCallbackHandler;
