import { Grid } from "@mui/material";
import { CurrencyDisplay } from "../../Shared/CurrencyDisplay";
import { formatMoment } from "src/utils/formatUtil";
import { makeStyles } from "@mui/styles";
import { Promotion } from "src/models/product";
import {useCountry } from "src/components/Context/CountryContext";
import moment from "moment-timezone";

const useStyles = makeStyles((theme) => ({
  promoContainer: {
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.grey[400]}`,

    "&:last-child": {
      borderBottom: "none",
    },
  },
  promoFooter: {
    fontSize: 12,
    color: theme.palette.grey[500],

    "& strong": {
      fontSize: 12,
    },
  },
}));

type PromotionItemProps = {
  promo: Promotion;
};

export const PromotionItem: React.FC<PromotionItemProps> = ({ promo }: PromotionItemProps) => {
  const classes = useStyles();
  const { timezone } = useCountry();

  const dateFrom = moment.tz(promo.dateFrom, timezone).format("DD/MM/YYYY");
  const dateTo = moment.tz(promo.dateTo, timezone).format("DD/MM/YYYY");

  return (
    <Grid container className={classes.promoContainer}>
      <Grid item xs={8}>
        <strong>{promo.description}</strong>
      </Grid>
      <Grid item xs={4} textAlign="right">
        <CurrencyDisplay as="strong" value={promo.price} />
      </Grid>

      <Grid item xs={8} className={classes.promoFooter}>
        <strong>From:</strong> {dateFrom}
        &nbsp;&nbsp;&nbsp;&nbsp;
        <strong>To:</strong> {dateTo}
      </Grid>
      <Grid item xs={4} textAlign="right" className={classes.promoFooter}>
        <strong>ID:</strong> {promo.id}
      </Grid>
    </Grid>
  );
};
