import { FC, useContext, useState } from "react";
import { Alert, Button, Grid, TextField, Box, Stack, List, ListItem } from "@mui/material";
import FileDropzone from "../../components/FileDropzone/FileDropzone";
import { fetchBrandImageAsync, updateBrandImageAsync } from "../../infrastructure/http/appApiService";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { LoadingButton } from "@mui/lab";
import bytesToSize from "src/components/FileDropzone/bytestoSize";
import { useCountry } from "src/components/Context/CountryContext";
import { hasPermission } from "src/infrastructure/auth/PermissionUtils";
import { UserRoles } from "src/infrastructure/auth/UserRoles";
import PageContext from "src/components/Context/PageContext";
import { useTitle } from "src/hooks/useTitle";

const buttonSX = {
  color: "white",
  backgroundColor: "black",
  "&:hover": {
    color: "white",
    backgroundColor: "#404040",
  },
};

const textSX = {
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
};

const BrandImages: FC = () => {
  const [newImageDisplayUrl, setNewImageDisplayUrl] = useState<string>(null);
  const [brandImage, setBrandImage] = useState<File>(null);
  const [searched, setSearched] = useState<boolean>();
  const [existingBrandImageUrl, setExistingBrandImageUrl] = useState<string>(null);
  const [brandName, setBrandName] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [searching, setSearching] = useState<boolean>(false);

  const { country } = useCountry();
  const [contextData] = useContext(PageContext);
  const { userProfile } = contextData;

  useTitle("Brand Images");

  if (!hasPermission(UserRoles.ProductBrandImages, userProfile.roles)) {
    window.location.href = "/";
    return null;
  }

  const handleDropFile = async ([file]: File[]) => {
    setError("");
    const maxFileSize = 20 * 1024; // 10 KB in bytes
    if (!file || (file && file.type !== "image/png")) {
      setError(`Invalid file type, type must be a .png image`);
      return;
    }
    if (file.size > maxFileSize) {
      setError(
        `File size exceeds the limit. Maximum allowed size is 20KB. Uploaded file size: ${bytesToSize(file.size)}`
      );
      return;
    }

    var imageUrl = URL.createObjectURL(file);

    if (file && file.type === "image/png") {
      const img = new Image();

      img.onload = () => {
        if (img.height === 40 && img.width === 190) {
          setBrandImage(file);
          setNewImageDisplayUrl(imageUrl);
        } else {
          setError(
            `Incorrect image dimensions, image must be 190 x 40 pixels. Uploaded file dimensions: ${img.width} x ${img.height}`
          );
        }
      };

      img.onerror = () => {
        setError("There was an error loading the image.");
      };

      img.src = imageUrl;
    } else {
      setError("Only PNG files are allowed.");
    }
  };

  const handleSearch = async () => {
    setSuccess(false);
    try {
      setSearching(true);
      const response = await fetchBrandImageAsync(brandName, country);
      if (response === "") {
        console.log("No content found for:", brandName);
        setSearched(true);
      } else if (response) {
        setExistingBrandImageUrl(response);
        setSearched(true);
      }
      setError("");
      setSearching(false);
    } catch (err) {
      setError("Failed to search. Please try again.");
      setSearching(false);
    }
  };

  const handleCancel = async () => {
    setBrandImage(null);
    setExistingBrandImageUrl(null);
    setNewImageDisplayUrl(null);
    setSearched(false);
    setBrandName("");
    setSuccess(false);
    setError("");
  };

  const handleUpload = async () => {
    try {
      setSuccess(false);
      setUploading(true);
      await updateBrandImageAsync(brandName, brandImage, country);
      setError("");
      setUploading(false);
      handleCancel();
      setSuccess(true);
    } catch (err) {
      setUploading(false);
      handleCancel();
      setError("Failed to upload. Please try again.");
    }
  };

  const infoLoaded = true;

  return (
    <>
      <Box component="h2" marginY={(theme) => `${theme.spacing(2)} !important`}>
        Brand Image Upload
      </Box>
      {/* Success Alert */}
      {success && (
        <Alert severity="success">
          Product Brand Image succesfully updated. Please allow a few minutes for the images on the PDP to reflect the
          change (may be longer during peak update periods)
        </Alert>
      )}
      {/* Error Alert */}
      {error && <Alert severity="error">{error}</Alert>}

      {/* No brand selected */}
      {existingBrandImageUrl == null && !searched && (
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sx={{ pt: 2, pb: 2 }}>
            <p>Search existing brand name before uploading.</p>
            <p>
              This should be the same value as <b>OnlineLabel</b> in IMS.
              <br />
              <b>Images will be uploaded for AU and NZ.</b>
            </p>
          </Grid>
          <Grid item>
            <TextField
              sx={textSX}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
              size="small"
              label="Brand Name"
              value={brandName}
              onChange={(e) => setBrandName(e.target.value)}
            />
          </Grid>
          <Grid item>
            <LoadingButton sx={buttonSX} loading={searching} onClick={handleSearch} variant="contained">
              SEARCH
            </LoadingButton>
          </Grid>
        </Grid>
      )}

      {/* Brand Selected */}

      {searched && (
        <div>
          <Box sx={{ pb: 2, pt: 2 }}>
            {newImageDisplayUrl ? `Image will be replaced as below` : `Existing brand Image`}
          </Box>
          <Stack direction="row">
            <img
              src={existingBrandImageUrl ?? "/static/no-image-found.png"}
              alt="Brand"
              style={{ height: "40px", width: "190px" }}
            />
            {newImageDisplayUrl && (
              <Stack direction="row" alignItems="center">
                <KeyboardArrowRightIcon sx={{ margin: "0px 16px" }} />
                <img src={newImageDisplayUrl} alt="Brand" style={{ height: "40px", width: "190px" }} />
              </Stack>
            )}
          </Stack>
          {brandName != null && searched && brandImage == null && (
            <Box sx={{ mt: 4 }}>
              <p>
                Upload file to {existingBrandImageUrl ? "replace existing image" : "add new image"}.<br />{" "}
                <b>Requirements:</b>
              </p>
              <List
                sx={{
                  listStyleType: "disc",
                  listStylePosition: "inside",
                }}
              >
                <ListItem sx={{ display: "list-item" }}>
                  190px*40px <b>.png</b> with <b>transparent background </b>and black text (hex code #000000)
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>Left justified with file size up to 20kb</ListItem>
              </List>
              <FileDropzone
                dropZoneText="Select file to replace Brand Image"
                accept={{
                  "image/png": [".png"],
                }}
                maxFiles={1}
                onDrop={handleDropFile}
                multiple={false}
                disabled={!infoLoaded}
              />
            </Box>
          )}
          <Stack direction="row" spacing={4} sx={{ mt: 4 }}>
            {newImageDisplayUrl && brandImage && (
              <Button sx={buttonSX} onClick={handleUpload} variant="contained">
                CONFIRM REPLACEMENT
              </Button>
            )}
            <LoadingButton sx={buttonSX} loading={uploading} onClick={handleCancel} variant="contained">
              Cancel
            </LoadingButton>
          </Stack>
        </div>
      )}
    </>
  );
};

export default BrandImages;
