import { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { Grid, Alert, AlertTitle } from "@mui/material";
import { Tab, Tabs } from "@jbhi-fi/lanyard-ui/components/Tabs";
import PinnedProducts from "./components/PinnedProducts";
import MyLists from "./components/MyLists";
import { SearchResults } from "./components/SearchResults";
import { getConsts } from "src/utils/consts";
import { SearchState } from "src/models/search";
import { useIsMobile } from "src/hooks/useIsMobile";
import { useShouldHideForCountry } from "src/hooks/useShouldHideForCountry";
import { COUNTRIES, useCountry } from "src/components/Context/CountryContext";
import { ProductCountBadge } from "./components/ProductCountBadge";
import { useTitle } from "src/hooks/useTitle";

const useStyles = makeStyles((theme) => ({
  searchInfoContainer: {
    marginTop: 100,
    marginBottom: 100,
  },
  section: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      marginBottom: 0,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "750px",
    },
  },
  title: {
    marginBottom: `${theme.spacing(1)} !important`,
  },
}));

enum SearchTab {
  JBProducts = "JBProducts",
  Marketplace = "Marketplace",
}

type SearchingProps = {
  pageSize?: number;
  errorMessage?: string;
  searchState?: SearchState;
  onJbPageChange: (newJbPage: number) => void;
  onMpPageChange: (newMpPage: number) => void;
};

export const Searching: FC<SearchingProps> = ({
  pageSize,
  errorMessage,
  searchState,
  onJbPageChange,
  onMpPageChange,
}: SearchingProps) => {
  const classes = useStyles();

  const history = useHistory();
  const isMobile = useIsMobile();
  const { country } = useCountry();
  const shouldHideForNZ = useShouldHideForCountry(COUNTRIES.NZ);

  const hasSearchResults = searchState.totalRows > 0;
  const hideMpSearchResults = !shouldHideForNZ || searchState.mpSearchState.rows === 0;

  const initialActiveTab =
    shouldHideForNZ && searchState.jbSearchState.rows === 0 && searchState.mpSearchState.rows > 0
      ? SearchTab.Marketplace
      : SearchTab.JBProducts;

  const [activeTab, setActiveTab] = useState(initialActiveTab);

  const consts = getConsts();

  useTitle("Products");

  const onProductSelected = (sku: string) => {
    if (!sku) {
      return;
    }

    history.push(`${consts.routerPath.product}/${sku}`);
  };

  const onListClick = async (row: any) => {
    if (!row) {
      return;
    }

    document.dispatchEvent(new CustomEvent("onTrackLists"));
    history.push(`${consts.routerPath.lists}/${row.id}`);
  };

  const JbSearchResults = () => (
    <SearchResults
      pageSize={pageSize}
      searchResultState={searchState.jbSearchState}
      onPageChange={onJbPageChange}
      onRowClick={onProductSelected}
    />
  );

  const MpSearchResults = () => (
    <SearchResults
      pageSize={pageSize}
      searchResultState={searchState.mpSearchState}
      onPageChange={onMpPageChange}
      onRowClick={onProductSelected}
    />
  );

  const MobileSearchPanel = () => (
    <Grid item xs={12} className={classes.section}>
      <h3 className={classes.title}>Search Results</h3>
      {shouldHideForNZ ? (
        <Tabs name="tabs" activeTab={activeTab} onChange={(_e, _i, value) => setActiveTab(value as SearchTab)}>
          <Tab label={<>JB Products</>} value={SearchTab.JBProducts} variant="Modern">
            <JbSearchResults />
          </Tab>

          <Tab
            label={
              <>
                Marketplace <ProductCountBadge count={searchState.mpSearchState.rows} marginX={1} />
              </>
            }
            value={SearchTab.Marketplace}
            variant="Modern"
          >
            <MpSearchResults />
          </Tab>
        </Tabs>
      ) : (
        <JbSearchResults />
      )}
    </Grid>
  );

  const DesktopSearchPanel = () => (
    <>
      <Grid item md={hideMpSearchResults ? 9 : 6} lg={hideMpSearchResults ? 9 : 5} className={classes.section}>
        <h3 className={classes.title}>JB Products</h3>
        <JbSearchResults />
      </Grid>
      {!hideMpSearchResults && (
        <Grid item md={6} lg={5} className={classes.section}>
          <h3 className={classes.title}>Marketplace</h3>
          <MpSearchResults />
        </Grid>
      )}
    </>
  );

  return (
    <>
      {errorMessage && (
        <Alert severity="error" sx={{ width: "100%" }}>
          <AlertTitle>Error</AlertTitle>
          {errorMessage}
        </Alert>
      )}

      <Grid container justifyContent="space-evenly">
        {hasSearchResults && <>{isMobile ? <MobileSearchPanel /> : <DesktopSearchPanel />}</>}

        {!hasSearchResults && (
          <>
            <Grid item xs={12} md={6} lg={5} className={classes.section}>
              <h3 className={classes.title}>Pinned Products</h3>
              <PinnedProducts onRowClick={onProductSelected} />
            </Grid>

            <Grid item xs={12} md={6} lg={5} className={classes.section}>
              <h3 className={classes.title}>My Lists</h3>
              <MyLists onRowClick={onListClick} />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};
