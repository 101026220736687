import { Country } from "src/components/Context/CountryContext";
import { InventoryTransaction, Promotion } from "src/models/product";
import { dateStrToMoment } from "../../utils/formatUtil";
import {
  getSearchAsync,
  getSearchMessagingProductsAsync,
  getProductAsync,
  getBrandImageAsync,
  postBrandImageAsync,
  postWasPricesAsync,
  getPurchaseOrdersAsync,
  getPromosAsync,
  getInventoryTransactionsAsync,
  patchProductAsync,
  postPinnedProducts,
  getPinnedProducts,
  postMyListItem,
  getMyLists,
  getMyListDetails,
  deleteMyList,
  deleteMyListItem,
  getInitialAppData,
  getReleaseNotes,
  getMPPricingHistoryAsync,
  getJBPricingHistoryAsync,
  getMessageByIdAsync,
  postMessageTemplateCreateAsync,
  putMessageTemplateUpdateAsync,
  postMessageImageAsync,
  getSearchMessagingProductsBySkusAsync,
  postMessageImageCopyAsync,
} from "../../utils/productAppApiHelper";
import { GetProductResponse, GetPurchaseOrdersResponse } from "src/models/product";
import { GetAppDataResponse, GetProductListProductResponse, ProductList } from "src/models/appData";
import { PaginatedSearchResults, SearchResponse, SearchResult, SearchResults } from "src/models/search";
import { sanitiseString } from "src/utils/stringUtils";
import { MPPricingHistoryResponse } from "src/models/product/MPPricingHistoryResponse";
import { JBPricingHistoryResponse } from "src/models/product/JBPricingHistoryResponse";
import { CreateMessageModel } from "src/validationSchemas";

const fetchSearchAsync = async (
  query: string,
  jbPage: number,
  mpPage: number,
  pageSize: number,
  country: Country
): Promise<SearchResponse> => {
  try {
    return await getSearchAsync(query, jbPage, mpPage, pageSize, country);
  } catch (error) {
    return {
      totalRows: 0,
      jbResults: { rows: 0, results: [] },
      mpResults: { rows: 0, results: [] },
    } as SearchResponse;
  }
};

const fetchMessagingProductsAsync = async (
  query: string,
  page: number,
  pageSize: number,
  country: Country
): Promise<SearchResults> => {
  try {
    return await getSearchMessagingProductsAsync(query, page, pageSize, country);
  } catch (error) {
    return {
      rows: 0,
      results: [],
    } as SearchResults;
  }
};

const fetchMessagingProductsBySkusAsync = async (
  skus: string[],
  country: Country,
  page: number = 1,
  pageSize: number = 10
): Promise<PaginatedSearchResults> => {
  try {
    return await getSearchMessagingProductsBySkusAsync(skus, country, page, pageSize);
  } catch (error) {
    console.error("Error in fetchMessagingProductsBySkusAsync:", error);
    return {
      totalCount: 0,
      page: page,
      pageSize: pageSize,
      results: [],
    } as PaginatedSearchResults;
  }
};

const fetchProductDetailAsync = async (
  sku: string,
  country: Country,
  locationId?: number
): Promise<GetProductResponse> => {
  return await getProductAsync(sku, country, locationId);
};

const fetchJBPricingHistoryAsync = async (sku: string, country: Country): Promise<JBPricingHistoryResponse[]> => {
  return await getJBPricingHistoryAsync(sku, country);
};

const fetchMPPricingHistoryAsync = async (sku: string, country: Country): Promise<MPPricingHistoryResponse> => {
  return await getMPPricingHistoryAsync(sku, country);
};

const fetchBrandImageAsync = async (brandName: string, country: Country): Promise<string> => {
  return await getBrandImageAsync(brandName, country);
};

const updateBrandImageAsync = async (brandName: string, image: any, country: Country) => {
  return await postBrandImageAsync(brandName, image, country);
};

const sendWasPricesAsync = async (wasPrices, country) => {
  return await postWasPricesAsync(wasPrices, country);
};

const fetchMessageByIdAsync = async (id: string, country: Country): Promise<CreateMessageModel> => {
  return await getMessageByIdAsync(id, country);
};

const createMessageTemplateAsync = async (requestObject: any, country: Country): Promise<any> => {
  return await postMessageTemplateCreateAsync(requestObject, country);
};

const updateMessageTemplateAsync = async (id: string, requestObject: any, country: Country): Promise<any> => {
  return await putMessageTemplateUpdateAsync(id, requestObject, country);
};

const fetchInventoryTransactionsAsync = async (
  sku: string,
  locationId: number,
  country: Country
): Promise<InventoryTransaction[]> => {
  try {
    const response = await getInventoryTransactionsAsync(sku, locationId, country);
    const transactions = response.map((t) => {
      const timestampStr =
        t.createdSourceTimestamp === "0001-01-01T00:00:00+00:00" ? t.createdTimestamp : t.createdSourceTimestamp;

      return {
        id: t.id,
        sku: t.sku,
        locationId: t.locationId,
        transactionType: t.transactionType,
        operationType: t.createdOperationType,
        timestamp: dateStrToMoment(timestampStr),
        externalItemIdentifier: t.externalItemIdentifier,
        quantity: t.quantity,
        createdByUserId: t.createdByUserId,
        channel: t.channel,
        status: t.transactionStatus,
      } as InventoryTransaction;
    });
    return transactions;
  } catch (error) {
    console.log(error);
  }
  return [];
};

const fetchPurchaseOrdersAsync = async (sku: string, country: Country): Promise<GetPurchaseOrdersResponse[]> => {
  try {
    const response = await getPurchaseOrdersAsync(sku, country);
    return response;
  } catch (error) {
    console.log(error);
  }
  return [];
};

const fetchPromosAsync = async (sku: string, locationId: number, country: Country): Promise<Promotion[]> => {
  try {
    const response = await getPromosAsync(sku, locationId, country);
    const promos = response.map((p) => {
      return {
        id: p.promoId,
        description: p.description.trim(),
        dateFrom: dateStrToMoment(p.from),
        dateTo: dateStrToMoment(p.to),
        price: p.price,
      } as Promotion;
    });
    return promos;
  } catch (error) {
    console.log(error);
  }
  return [];
};

const patchProductDetailAsync = async (product, country) => {
  var patch = {
    seo: product.seo,
  };
  return await patchProductAsync(patch, product.sku, country);
};

const fetchPinnedProductsAsync = async (country: Country): Promise<SearchResult[]> => {
  return await getPinnedProducts(country);
};

const updatePinnedProductsAsync = async (pinnedProducts: string[], country: Country) => {
  return await postPinnedProducts(pinnedProducts, country);
};

const fetchMyListsAsync = async (country: Country): Promise<ProductList[]> => {
  const myLists = await getMyLists(country);
  const renderedMyLists = myLists?.map((m) => {
    return {
      id: sanitiseString(m.name),
      name: m.name,
      numberOfValues: m.values?.length ?? 0,
      values: m.values,
    } as ProductList;
  });
  return renderedMyLists;
};

const fetchMyListDetailsAsync = async (name: string, country: Country): Promise<GetProductListProductResponse[]> => {
  return await getMyListDetails(name, country);
};

const updateMyListAsync = async (name: string, sku: string, country: Country) => {
  const requestObject = { name: name, value: sku };
  return await postMyListItem(requestObject, country);
};

const deleteMyListAsync = async (name: string, country: Country): Promise<void> => {
  const requestObject = { name };
  return await deleteMyList(requestObject, country);
};

const deleteMyListItemAsync = async (name: string, sku: string, country: Country) => {
  const requestObject = { name: name, value: sku };
  return await deleteMyListItem(requestObject, country);
};

const fetchInitialAppDataAsync = async (country: Country): Promise<GetAppDataResponse> => {
  return await getInitialAppData(country);
};

const fetchReleaseNotesAsync = async (country: Country): Promise<any[]> => {
  return await getReleaseNotes(country);
};

const uploadMessageImageAsync = async (image: File, country: Country): Promise<any> => {
  return await postMessageImageAsync(image, country);
};

const copyMessageImageAsync = async (image: any, country: Country): Promise<any> => {
  return await postMessageImageCopyAsync(image, country);
};

export {
  fetchSearchAsync,
  fetchMessagingProductsAsync,
  fetchMessagingProductsBySkusAsync,
  fetchProductDetailAsync,
  fetchBrandImageAsync,
  updateBrandImageAsync,
  sendWasPricesAsync,
  fetchPurchaseOrdersAsync,
  fetchPromosAsync,
  fetchInventoryTransactionsAsync,
  patchProductDetailAsync,
  fetchPinnedProductsAsync,
  updatePinnedProductsAsync,
  fetchMyListsAsync,
  fetchMyListDetailsAsync,
  updateMyListAsync,
  deleteMyListAsync,
  deleteMyListItemAsync,
  fetchInitialAppDataAsync,
  fetchReleaseNotesAsync,
  fetchMPPricingHistoryAsync,
  fetchJBPricingHistoryAsync,
  fetchMessageByIdAsync,
  createMessageTemplateAsync,
  updateMessageTemplateAsync,
  uploadMessageImageAsync,
  copyMessageImageAsync,
};
