import {
  AccountInfo,
  AuthError,
  Configuration,
  InteractionRequiredAuthError,
  PopupRequest,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest,
} from "@azure/msal-browser";
import { AuthenticationState, loginRequest, LoginType } from "./constants";
import type { IAuthProvider } from "./types";

const config = {
  auth: {
    authority: process.env.REACT_APP_APP_AUTHORITY,
    clientId: process.env.REACT_APP_CLIENT_ID,
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

class AuthProvider extends PublicClientApplication implements IAuthProvider {
  loginType: number;
  authenticationState: string;
  username: string | null;

  constructor(logintype: number, config: Configuration) {
    super(config);
    this.loginType = logintype;
    this.authenticationState = AuthenticationState.UnAuthenticated;
    this.username = null;
  }

  getAccount() {
    if (this.username) {
      return this.getAccountByUsername(this.username);
    }
    const accounts = super.getAllAccounts();
    if (accounts && accounts.length === 1) {
      this.username = accounts[0].username;
      return accounts[0];
    }
  }

  async getToken(request: SilentRequest | RedirectRequest | PopupRequest, account?: AccountInfo) {
    const requestAccount = account || this.getAccount();

    request = { ...request, account: requestAccount };

    return super.acquireTokenSilent(request).catch((error) => {
      if (error instanceof InteractionRequiredAuthError) {
        if (this.loginType === LoginType.LoginRedirect) {
          super.acquireTokenRedirect(request);
          return;
        } else {
          return super.acquireTokenPopup(request);
        }
      }
      throw error;
    });
  }

  _handleResponse(tokenResponse) {
    if (tokenResponse) {
      this.authenticationState = AuthenticationState.Authenticated;
      return tokenResponse;
    }

    const account = this.getAccount();
    if (!account) {
      return null;
    }

    this.authenticationState = AuthenticationState.InProgress;
    return this.getToken(loginRequest, account)
      .then((response) => this._handleResponse(response))
      .catch((error) => {
        if (error instanceof AuthError) {
          console.error("Error occured during getToken", error);
        }
        throw error;
      });
  }

  // use this promise to handle the response and error.
  async handleRedirectPromise() {
    return super.handleRedirectPromise().then((response) => this._handleResponse(response));
  }

  async login() {
    if (this.loginType === LoginType.LoginPopup) {
      return super.loginPopup(loginRequest);
    } else if (this.loginType === LoginType.LoginRedirect) {
      return super.loginRedirect(loginRequest);
    }
  }

  async logout() {
    super.logoutRedirect({
      account: super.getAccountByUsername(this.username),
      postLogoutRedirectUri: "/",
    });
  }
}

const authProvider = new AuthProvider(LoginType.LoginRedirect, config);

export { authProvider };
