import { AccountInfo, AuthenticationResult, PopupRequest, RedirectRequest, SilentRequest } from "@azure/msal-browser";
import { v4 } from "uuid";
import { AuthenticationState } from "./constants";
import jbJsAsyncCallbackHandler from "../../jbJsAsyncCallbackHandler";
import { IAuthProvider } from "./types";

const androidAuthProvider: IAuthProvider = {
  authenticationState: AuthenticationState.UnAuthenticated,
  login(loginHint?: string | null) {
    this.authenticationState = AuthenticationState.InProgress;
    window.JbNativeAuthProvider!.login(loginHint || null);
  },
  logout() {
    window.JbNativeAuthProvider!.logout();
    this.authenticationState = AuthenticationState.UnAuthenticated;
  },
  getToken(
    request: SilentRequest | RedirectRequest | PopupRequest,
    _account?: AccountInfo
  ): Promise<AuthenticationResult | null> {
    return new Promise((resolve, reject) => {
      const callbackId = v4();
      const requestJson = JSON.stringify(request);

      jbJsAsyncCallbackHandler.registerAsyncCallback(callbackId, resolve, reject);
      window.JbNativeAuthProvider!.getToken(callbackId, requestJson);
    });
  },
  handleRedirectPromise(): Promise<AuthenticationResult | null> {
    this.authenticationState = AuthenticationState.InProgress;
    return new Promise<AuthenticationResult | null>((resolve, reject) => {
      const callbackId = v4();

      jbJsAsyncCallbackHandler.registerAsyncCallback(callbackId, resolve, reject);
      window.JbNativeAuthProvider!.handleRedirectPromise(callbackId);
    }).then((result) => {
      this.authenticationState = result ? AuthenticationState.Authenticated : AuthenticationState.UnAuthenticated;
      return result;
    });
  },
};

export const authProvider: IAuthProvider | null = window.JbNativeAuthProvider ? androidAuthProvider : null;
