import React from "react";
import { useCountry } from "../Context/CountryContext";
import { TextField } from "../Form/TextField";
import { useIsMobile } from "src/hooks/useIsMobile";
import { Grid, Box, useMediaQuery } from "@mui/material";
import SquareSwitch from "../DrawerMenu/SquareSwitch";

type SearchBoxProps = {
  searchText: string;
  setSearchText: (text: string) => void;
  onSearch: (value: string) => void;
};

export const SearchBox: React.FC<SearchBoxProps> = ({ searchText, setSearchText, onSearch }) => {
  const isMobile = useIsMobile();
  const { country, canSwitchCountries, changeCountry } = useCountry();

  const handleInputChange = (newValue: string) => {
    setSearchText(newValue);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onSearch(searchText);
    }
  };

  return (
    <Grid container alignItems="center" spacing={1} sx={{ display: "flex", flexWrap: "nowrap" }}>
      <Grid item sx={{ flexGrow: 1, minWidth: "200px" }}>
        <Box sx={{ width: '100%' }}>
          <TextField
            id="search-textfield"
            name="default-textfield"
            value={searchText}
            label={isMobile ? `Search ${country}` : "Search By: SKU | Description | Artist | Model | Brand"}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
        </Box>
      </Grid>

      {canSwitchCountries && !isMobile && (
        <Grid item sx={{ flexShrink: 0, marginLeft: "10px" }}>
          <Box
            sx={{
              border: "1px solid black",
              borderRadius: "5px",
              display: "inline-block",
              whiteSpace: "nowrap",
              minWidth: "60px",
            }}
          >
            <SquareSwitch leftText="AU" rightText="NZ" currentCountry={country} onChange={changeCountry} />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
