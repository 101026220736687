import usePromos from "src/hooks/http/usePromos";
import { GetProductResponse } from "src/models/product";
import { dateType, currencyType } from "src/utils/dataGridFormat";
import DataGrid from "./DataGrid";
import { GridColDef } from "@mui/x-data-grid-pro";
import {useCountry } from "src/components/Context/CountryContext";
import { getConsts } from "src/utils/consts";
import moment from "moment-timezone";

type PromotionTabProps = {
  product: GetProductResponse;
};

const PromotionTab: React.FC<PromotionTabProps> = ({ product }: PromotionTabProps) => {
  const { isLoading, data } = usePromos(product.sku);
  const { timezone } = useCountry();
  const consts = getConsts();


  const promoColumns: GridColDef[] = [
    { field: "id", headerName: "Id" },
    { field: "description", headerName: "Description", flex: 1 },
    { 
      field: "dateFrom", 
      headerName: "From", 
      valueFormatter: (params) => 
        params.value 
          ? moment.utc(params.value).tz(timezone).format("DD/MM/YYYY") 
          : ""
    },
    { 
      field: "dateTo", 
      headerName: "To", 
      valueFormatter: (params) => 
        params.value 
          ? moment.utc(params.value).tz(timezone).format("DD/MM/YYYY") 
          : ""
    },
    { field: "price", headerName: "Price", ...currencyType },
  ];

  // Sort promos by dateFrom in descending order
  const sortedData = data?.slice().sort((a, b) => {
    if (moment(a.dateFrom) === null) return 1;
    if (moment(b.dateFrom) === null) return -1;
    return moment(b.dateFrom).diff(moment(a.dateFrom))
  });

  return (
    <DataGrid
      isLoading={isLoading}
      dataGridId={consts.dataGridIds.promotionsDataGridId}
      defaultPageSize={100}
      marginTop={4}
      columns={promoColumns}
      rows={sortedData}
      hideFooter
    />
  );
};

export default PromotionTab;
