import React from "react";
import { Grid, CircularProgress } from "@mui/material";
import useReleaseNotes from "../../hooks/http/useReleaseNotes";
import { ReleaseNoteDetails } from "../../components/ReleaseNotes/ReleaseNoteDetails";
import makeStyles from "@mui/styles/makeStyles";
import { useTitle } from "src/hooks/useTitle";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: `${theme.spacing(2)} !important`,
  },
}));

const ReleaseNotesFull = React.memo(() => {
  const classes = useStyles();
  const { isLoading, data } = useReleaseNotes();

  useTitle("Release Notes");

  return (
    <Grid container direction="column" alignContent="center">
      <h2 className={classes.title}>Release Notes</h2>
      {isLoading && <CircularProgress />}
      {!isLoading && <ReleaseNoteDetails releaseNotes={data} />}
    </Grid>
  );
});

export default ReleaseNotesFull;
