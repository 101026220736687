export const loginRequest = {
  scopes: ["openid", "profile", "User.Read"],
  redirectUri: window.location.origin,
};

export const graphTokenRequest = {
  scopes: ["User.Read"],
  redirectUri: window.location.origin,
};

export const apiTokenRequest = {
  scopes: [process.env.REACT_APP_API_REQUEST_TOKEN],
  redirectUri: window.location.origin,
};

export const LoginType = {
  LoginRedirect: 0,
  LoginPopup: 1,
};

export const AuthenticationState = {
  Authenticated: "Authenticated",
  UnAuthenticated: "UnAuthenticated",
  InProgress: "InProgress",
};
