import { useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams, useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { makeStyles } from "@mui/styles";
import { CreateMessageModel, createMessageSchema } from "src/validationSchemas";
import { AlertModal, AlertModalState } from "src/components/Modal";
import { generateErrorMessages } from "src/utils/formUtils";
import { MessageForm } from "./components/MessageForm";
import PageContext from "src/components/Context/PageContext";
import { hasPermission } from "src/infrastructure/auth/PermissionUtils";
import { UserRoles } from "src/infrastructure/auth/UserRoles";
import { Title } from "./components/Title";
import { ConditionTypes, MessageTypes } from "src/models/messaging";
import {
  createMessageTemplateAsync,
  updateMessageTemplateAsync,
  uploadMessageImageAsync,
  copyMessageImageAsync,
} from "src/infrastructure/http/appApiService";
import { Country, useCountry } from "src/components/Context/CountryContext";
import { getMessageByIdAsync } from "src/utils/productAppApiHelper";
import { getConsts } from "src/utils/consts";
import { ConfirmModal, ConfirmModalState } from "src/components/Modal";
import moment from "moment";
import { useTitle } from "src/hooks/useTitle";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: `${theme.spacing(4)} !important`,
    textAlign: "center",
  },
}));

const emptyMessage = {
  id: null,
  type: "" as MessageTypes,
  subType: "",
  hasDefaultOnlineTag: false,
  cashbackAmount: undefined,
  name: "",
  title: "",
  heading: "",
  shortDescription: "",
  description: "",
  tileImage: undefined,
  heroBannerImage: undefined,
  messageImage: undefined,
  startDate: undefined,
  startTime: undefined,
  finishDate: undefined,
  finishTime: undefined,
  start: undefined,
  finish: undefined,
  priority: 1,
  onlineTag: "",
  conditionTypes: [ConditionTypes.Sku],
  skuConditions: [],
  specificationConditions: [],
} as CreateMessageModel;

type MessageDetailsRouteParams = {
  id: string;
};

export const MessageDetails: React.FC = () => {
  const classes = useStyles();
  const consts = getConsts();

  const { id } = useParams<MessageDetailsRouteParams>();
  const { country } = useCountry();

  const [alertModalState, setAlertModalState] = useState<AlertModalState>({ show: false });
  const [confirmModalState, setConfirmModalState] = useState<ConfirmModalState>({ show: false });

  const formMethods = useForm<CreateMessageModel>({
    resolver: yupResolver<CreateMessageModel>(createMessageSchema),
    defaultValues: emptyMessage,
  });

  const [contextData] = useContext(PageContext);
  const { userProfile } = contextData;
  const history = useHistory();
  const [title, setTitle] = useState("Product Messaging");
  const isCopy = history.location?.pathname.includes("copy");

  useEffect(() => {
    formMethods.clearErrors();

    if (!id) {
      formMethods.reset(emptyMessage);
      return;
    }

    const loadMessageTemplate = async () => {
      const messageTemplate = await getMessageByIdAsync(id, country);
      if (isCopy) {
        messageTemplate.id = undefined;
        messageTemplate.name = `${messageTemplate.name} (Copy)`;
      }

      messageTemplate.startDate = messageTemplate.start
        ? moment(messageTemplate.start).format("DD/MM/YYYY")
        : undefined;
      messageTemplate.startTime = messageTemplate.start ? moment(messageTemplate.start).format("HH:mm:ss") : undefined;
      messageTemplate.finishDate = messageTemplate.finish
        ? moment(messageTemplate.finish).format("DD/MM/YYYY")
        : undefined;
      messageTemplate.finishTime = messageTemplate.finish
        ? moment(messageTemplate.finish).format("HH:mm:ss")
        : undefined;
      formMethods.reset(messageTemplate);

      setTitle(`${messageTemplate.name}`);
    };

    loadMessageTemplate();
  }, [id, formMethods, country]);

  useTitle(title);

  if (!hasPermission(UserRoles.ProductMessaging, userProfile.roles)) {
    window.location.href = "/";
    return null;
  }

  const processImage = async (imageData: any, country: Country): Promise<any> => {
    if (!imageData) return null;

    if (imageData.isDeleted) {
      return null;
    }

    if (imageData.isNew) {
      const result = await uploadMessageImageAsync(imageData.file, country);
      return result?.result;
    }

    if (isCopy) {
      const result = await copyMessageImageAsync(imageData, country);
      return result?.result;
    }

    return imageData;
  };

  const onFormSubmit = async (data: CreateMessageModel) => {
    try {
      data.start = moment(data.startDate + data.startTime, "DD/MM/YYYY HH:mm:ss").toISOString();
      data.finish = moment(data.finishDate + data.finishTime, "DD/MM/YYYY HH:mm:ss").toISOString();

      if (data.type === MessageTypes.Message) {
        data.onlineTag = null;
      }

      data.messageImage = await processImage(data.messageImage, country);
      data.tileImage = await processImage(data.tileImage, country);
      data.heroBannerImage = await processImage(data.heroBannerImage, country);

      if (data.id) {
        await updateMessageTemplateAsync(data.id, data, country);
        setConfirmModalState({
          title: "Success",
          message: <>Message template updated successfully.</>,
          confirmLabel: "OK",
          show: true,
          onConfirm: () => {
            history.push(`/${consts.page.messaging}`);
          },
        });
        return;
      }

      await createMessageTemplateAsync(data, country);

      setConfirmModalState({
        title: "Success",
        message: <>Message template created successfully.</>,
        confirmLabel: "OK",
        show: true,
        onConfirm: () => {
          history.push(`/${consts.page.messaging}`);
        },
      });
    } catch (error) {
      setAlertModalState({
        show: true,
        title: "Error",
        message: "An error occurred while saving the message template. Please try again later.",
        type: "error",
      });
    }
  };

  const onFormError = (data: any) => {
    const errorMessages = generateErrorMessages(data);

    setAlertModalState({
      show: true,
      title: "Error",
      message: (
        <>
          <p style={{ marginBottom: 15 }}>
            There are validation errors in the form. Please review and correct them before trying again.
          </p>
          <ul style={{ listStyle: "inside" }}>
            {errorMessages.map((message, index) => (
              <li key={index}>{message}</li>
            ))}
          </ul>
        </>
      ),
      type: "error",
    });
  };

  return (
    <>
      <Title className={classes.title}>{id ? "Edit Template" : "Create Template"}</Title>

      <FormProvider {...formMethods}>
        <MessageForm onFormSubmit={onFormSubmit} onFormError={onFormError} />
      </FormProvider>

      <AlertModal
        state={alertModalState}
        onClose={() => {
          setAlertModalState({
            show: false,
          });
        }}
      />

      <ConfirmModal state={confirmModalState} />
    </>
  );
};
