import moment from "moment-timezone";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useCountry } from "../Context/CountryContext";

export const DatePickerProvider: React.FC = ({ children }) => {
  const { timezone } = useCountry();

  const momentInstance = moment.tz.setDefault(timezone);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={momentInstance}>
      {children}
    </LocalizationProvider>
  );
};
